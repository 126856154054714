import {
  CashIcon,
  PlusCircleIcon,
  ReceiptTaxIcon,
  ShoppingCartIcon,
  ViewGridIcon,
  FilterIcon,
  CurrencyDollarIcon,
} from '@heroicons/vue/outline'

import { usePermissions } from '@/Hooks/Company/usePermissions.js'

import { pricingEnabled } from '@/Utils/useCompanySettings.js'

export default function useProductNavigation() {
  const { hasPermission } = usePermissions()

  return {
    overviewTitle: 'Products Overview',
    overviewRouteName: 'web.company.products.overview.show',
    sections: [
      {
        show: true,
        name: 'Your Services',
        description:
          'Your products are the services that you sell and will display on your order forms. You can categorize them or create addons.',
        children: [
          {
            show: hasPermission('product_view_any'),
            name: 'Main Products',
            description: 'The primary services you offer on your order forms.',
            routeIsInertia: true,
            targetRouteName: 'web.company.products.index',
            icon: ShoppingCartIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.products.index',
              'web.company.products.create',
              'web.company.products.edit',
            ],
          },
          {
            show: hasPermission('product_view_any'),
            name: 'Add-on Products',
            description:
              'Addon will display alongside main products when your customers are ordering. They can be applied to certain main products, or can always be offered.',
            routeIsInertia: true,
            targetRouteName: 'web.company.products.addons.index',
            icon: PlusCircleIcon,
            routeNamesToCheckIfCurrent: ['web.company.products.addons.index'],
          },
          {
            show: false && hasPermission('product_view_any'),
            name: 'Categories',
            description:
              'Categorize your products, allowing them to be displayed on different order forms.',
            routeIsInertia: false,
            targetRouteName: 'web.company.products.index',
            icon: ViewGridIcon,
            routeNamesToCheckIfCurrent: null,
          },
        ],
      },
      {
        show: pricingEnabled(),
        name: 'Adjustments',
        description:
          'Configure adjustments that will be applied to orders like travel fees, taxes and discounts.',
        children: [
          {
            show: hasPermission('fee_view_any'),
            name: 'Travel Fees',
            description:
              'Percentage, flat, or distance/time-based fees applied for users when servicing orders.',
            routeIsInertia: true,
            targetRouteName: 'web.company.fees.index',
            icon: CashIcon,
            routeNamesToCheckIfCurrent: ['web.company.fees.*'],
          },
          {
            show: hasPermission('tax_view_any'),
            name: 'Taxes',
            description:
              "Update your company's tax rates (state, county, and/or custom) applied for all new orders.",
            routeIsInertia: true,
            targetRouteName: 'web.company.taxes.rates.index',
            icon: ReceiptTaxIcon,
            routeNamesToCheckIfCurrent: ['web.company.taxes.*'],
          },
          {
            show: hasPermission('product_view_any'),
            name: 'Coupons & Promo Codes',
            description:
              'Create and manage coupons & promotion codes that can be applied to your orders.',
            routeIsInertia: true,
            targetRouteName: 'web.company.discounts.coupons.index',
            icon: CurrencyDollarIcon,
            routeNamesToCheckIfCurrent: [
              'web.company.discounts.coupons.*',
              'web.company.discounts.promotion-codes.*',
            ],
          },
          {
            show: hasPermission('settings_manage'),
            name: 'Product Filters',
            description: "Update your company's preferred way of filtering products for customers.",
            routeIsInertia: true,
            targetRouteName: 'web.company.product-filters.index',
            icon: FilterIcon,
            routeNamesToCheckIfCurrent: ['web.company.product-filters.*'],
          },
        ],
      },
    ],
  }
}
